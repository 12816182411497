import { ApiCore } from "./utilities/core";

const url = "application";
const boUrl = "/businessObject";

export const appTasks = new ApiCore({
  getAll: true,
  getSingle: true,
  getDomains: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  url: url,
  boUrl: boUrl,
});
