import React, { useState } from "react";
import {
  Divider,
  Drawer,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import AppsIcon from "@mui/icons-material/Apps";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HandymanIcon from "@mui/icons-material/Handyman";
import LogoutIcon from "@mui/icons-material/Logout";
import DataObjectIcon from "@mui/icons-material/DataObject";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { matchPath } from "react-router";
import { styled } from "@mui/material/styles";
import Copyright from "../components/common/Copyright";
import useAuth from "../components/auth/useAuth";
import Logo from "../components/common/Logo";
import Feedback from "./Feedback/Feedback";

const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    padding: 0;
    backgroundColor: ${theme.colors.primary},

    & > .MuiList-root {
      padding: 0 ${theme.spacing(4)} ${theme.spacing(4)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(2, 2)};
      line-height: 1.4;
    }
`
);

const MenuItem = ({ to, title, icon, seletedPaths, onClick }) => {
  const { pathname } = useLocation();

  const selected = (seletedPaths || [to]).some((path) =>
    matchPath({ path: path, exact: true }, pathname)
  );
  return (
    <ListItem
      disablePadding
      button
      to={to}
      component={Link}
      selected={selected}
    >
      <ListItemButton
        selected={selected}
        sx={{ fontSize: 14 }}
        onClick={onClick}
      >
        <ListItemIcon style={{ minWidth: "40px" }}>{icon}</ListItemIcon>
        {title}
      </ListItemButton>
    </ListItem>
  );
};

export default function Navigator(props) {
  const { ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [showFb, setShowFb] = useState(false);

  const handleFBClose = (val) => {
    setShowFb(val);
    console.log("Set FB");
    logout().then(() => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const handleLogout = () => {
    console.log("Logout");
    setShowFb(true);
    // logout().then(() => {
    //   localStorage.clear();
    //   navigate("/login");
    // });
  };

  return (
    <Drawer variant="permanent" {...other}>
      <Box sx={{ minHeight: 70 }}>
        <Logo />
      </Box>
      <Divider />
      <MenuWrapper>
        <Box>
          <MenuItem to="/apps" icon={<AppsIcon />} title="All Applications" />
          <MenuItem
            to="/dashboard/appsettings"
            icon={<SettingsIcon />}
            title="Application Settings"
          />
          <MenuItem
            to="/dashboard/bolist"
            icon={<DataObjectIcon />}
            title="Business Objects"
            seletedPaths={["dashboard/bodetails", "/dashboard/bolist"]}
          />
          <MenuItem
            to="/dashboard/relations"
            icon={<LinkIcon />}
            title="Relation"
          />
          <MenuItem
            to="/dashboard/actions"
            icon={<AccountTreeIcon />}
            title="Actions"
            seletedPaths={["dashboard/actionsteps", "/dashboard/actions"]}
          />

          <MenuItem
            to="/dashboard/deployment"
            icon={<HandymanIcon />}
            title="Deployment"
          />

          <Divider sx={{ mt: 2 }} />
          <MenuItem
            to="#"
            icon={<LogoutIcon />}
            title="Logout"
            onClick={handleLogout}
          />
        </Box>
      </MenuWrapper>

      <Box
        component="footer"
        style={{
          width: "250px",
          position: "fixed",
          bottom: 0,
          textAlign: "center",
        }}
      >
        <Feedback showFb={showFb} onCloseFB={handleFBClose} />
        <Copyright />
      </Box>
    </Drawer>
  );
}
