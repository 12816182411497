import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

function Header(props) {
  const { onDrawerToggle, heading } = props;
  return (
    <React.Fragment>
      <AppBar
        style={{ background: "#fff", boxShadow: "none" }}
        position="sticky"
        elevation={0}
      >
        <Toolbar sx={{ height: "70px" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item md>
              <Typography
                fontWeight="linear"
                color="primary"
                sx={{
                  padding: 2,
                  marginLeft: 1,
                }}
                variant="h2"
              >
                {heading}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight="linear"
                color="primary"
                sx={{
                  padding: 2,
                  marginRight: 1,
                }}
                variant="body2"
              >
                Admin
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
