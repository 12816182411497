import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Link,
  BottomNavigation,
  Alert,
  AlertTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SignupForm from "./SignUp";
import { useState } from "react";
import LoginForm from "./SignIn";
import useAuth from "../components/auth/useAuth";
import { useNavigate } from "react-router-dom";
import Copyright from "../components/common/Copyright";
import ForgotPassword from "./ForgotPassword";
import Notification from "../components/common/Notification";

import Logo from "../components/common/Logo";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Login() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let location = params.get("l");
  console.log("location " + location);
  const [isSignIn, setIsSignIn] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
    duration: 3000,
  });
  const { authed } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Authed ", authed);
    if (authed === true) {
      navigate("/apps");
      window.location.reload();
    }
  }, [authed, navigate]);

  useEffect(() => {
    if (location === "signup") setIsSignIn(false);
  }, [location]);

  const handleLoginState = (state) => {
    //setIsSignIn(state);
    setShowAlert(true);
    setNotify({
      isOpen: true,
      message: "Account is created. Please check your email for confirmation. ",
      type: "success",
    });
  };

  return (
    <>
      <MainContent>
        <Notification notify={notify} setNotify={setNotify} />
        <Container
          maxWidth="xs"
          sx={{ backgroundColor: "#fff", borderRadius: 1, minHeight: 500 }}
        >
          <Box textAlign="center">
            <Logo />
            <Divider sx={{ my: 1 }} />
            {showAlert ? (
              <Alert
                variant="filled"
                severity="info"
                sx={{ textAlign: "left" }}
              >
                <AlertTitle sx={{ textAlign: "left" }}>
                  Account is created successfully!
                </AlertTitle>
                Please check your
                <strong> email </strong>for confirmation link.
              </Alert>
            ) : (
              <></>
            )}
            {isSignIn ? (
              <Container maxWidth="xs">
                <Typography variant="h2" sx={{ mt: 4, mb: 2, fontWeight: 100 }}>
                  Login
                </Typography>
                <LoginForm />
                Forgot password? &nbsp;
                <Link
                  href="#"
                  variant="body1"
                  onClick={() => {
                    setResetPassword(true);
                    setIsSignIn(false);
                    setShowAlert(false);
                  }}
                >
                  Reset password
                </Link>
                <br />
                <br />
                Don't have login?, please &nbsp;
                <Link
                  href="#"
                  variant="body1"
                  onClick={() => {
                    setIsSignIn(false);
                    setShowAlert(false);
                  }}
                >
                  Signup
                </Link>
              </Container>
            ) : resetPassword ? (
              <Container maxWidth="xs">
                <Typography variant="h2" sx={{ mt: 4, mb: 2, fontWeight: 100 }}>
                  Reset Password
                </Typography>
                <ForgotPassword />
                <Link
                  href="#"
                  variant="body1"
                  onClick={() => {
                    setIsSignIn(true);
                    setResetPassword(false);
                    setShowAlert(false);
                  }}
                >
                  Login
                </Link>
              </Container>
            ) : (
              <Container maxWidth="xs">
                <Typography variant="h2" sx={{ mt: 4, mb: 2, fontWeight: 100 }}>
                  Signup
                </Typography>
                <SignupForm onSignup={(value) => handleLoginState(value)} />
                Already signed up! please &nbsp;
                <Link
                  href="#"
                  variant="body1"
                  onClick={() => {
                    setIsSignIn(true);
                  }}
                >
                  Login
                </Link>
              </Container>
            )}
          </Box>
          <Divider sx={{ my: 4 }} />
        </Container>
        <BottomNavigation sx={{ backgroundColor: "transparent" }}>
          <Copyright />
        </BottomNavigation>
      </MainContent>
    </>
  );
}

export default Login;
