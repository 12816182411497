import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

export const FormInputText = ({
  name,
  control,
  label,
  rules,
  type = "text",
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          label={label}
          variant="outlined"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
          type={type}
        />
      )}
      rules={rules}
    />
  );
};
