import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" >
      {"Copyright © "}
      <a target="_blank" href={"https://www.appiflow.com"}>AppiFlow</a>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
