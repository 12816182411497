import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { PureLightTheme } from "../components/common/PureLightTheme";
import { FormInputText } from "../components/MUI/FormInputText";
import { useMutation, useQueryClient } from "react-query";
import api from "../services/api/api";
import Loading from "../components/common/Loading";
import Notification from "../components/common/Notification";
import centerForm from "../styles/formCenter";

const SignupForm = (props) => {
  const classes = centerForm();
  const { handleSubmit, control, reset } = useForm();
  const queryClient = useQueryClient();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const createUser = async (data) => {
    console.log(data);
    return await api.postData("user/signup", data);
  };

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: async (response) => {
      const data = response.data;
      const status = response.status;
      if (status === 201) {
        reset();
        props.onSignup(true);
        return;
      }
    },
    onError: async (error) => {
      console.log(
        error.response.status,
        "there was an error",
        error.response.data
      );
      setNotify({
        isOpen: true,
        message: error.response.data.details,
        type: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("createUser");
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const login = {
      ...data,
    };
    mutate(login);
  };

  return (
    <ThemeProvider theme={PureLightTheme}>
      <Loading loading={isLoading} />
      <Notification notify={notify} setNotify={setNotify} />
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <FormInputText
          name="name"
          label="Name"
          control={control}
          rules={{
            required: "Please enter valid name",
            minLength: { value: 2, message: "Please enter valid name" },
          }}
        />
        <FormInputText
          name="email"
          label="Email"
          control={control}
          rules={{
            required: "Email required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email",
            },
          }}
          type="email"
        />

        <FormInputText
          name="password"
          label="Password"
          control={control}
          rules={{
            required: "Password required",
            pattern: {
              value:
                /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,30}$/,
              message:
                "Password must be 8 characters. 1 Upper case. 1 special character. 2 numbers ",
            },
          }}
          type="password"
        />

        <div>
          <Button variant="contained" onClick={() => reset()}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Signup
          </Button>
        </div>
      </form>
    </ThemeProvider>
  );
};

export default SignupForm;
