import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Notification from "../components/common/Notification";
import { appTasks } from "../services/api/apps";
import Loading from "../components/common/Loading";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Button,
  Container,
  Tooltip,
  Divider,
} from "@mui/material";

//const authTypeList = ["None", "Basic", "OAuth", "SAML", "OpenID"];
const authTypeList = [
  { name: "None", disabled: false },
  { name: "Basic", disabled: true },
  { name: "OAuth", disabled: true },
  { name: "SAML", disabled: true },
  { name: "OpenID", disabled: true },
];
const oAuthVendorList = ["Google", "Facebook", "Keycloak", "Github"];

// const configTypeList = [
//   "Application Properties",
//   "System Enviornment",
//   "Kubernetes Configuration",
//   "AWS Systems Manager Parameter Store",
// ];

const configTypeList = [
  { name: "Application Properties", disabled: false },
  { name: "System Enviornment", disabled: true },
  { name: "Kubernetes Configuration", disabled: true },
  { name: "AWS Systems Manager Parameter Store", disabled: true },
];

// const secretTypeList = [
//   "Application Properties",
//   "AWS Secret Manager",
//   "Hashicorp Vault",
//   "Kubernetes Secrets",
// ];

const secretTypeList = [
  { name: "Application Properties", disabled: false },
  { name: "AWS Secret Manager", disabled: true },
  { name: "Hashicorp Vault", disabled: true },
  { name: "SAML", disabled: true },
  { name: "Kubernetes Secrets", disabled: true },
];
export default function AppSettings() {
  const app = JSON.parse(localStorage.getItem("app"));
  const [authType, setAuthType] = useState("");
  const [oauthType, setOAuthType] = useState([]);
  const [configType, setConfigType] = useState("");
  const [secretType, setSecretType] = useState("");
  const [isLagecy, setIsLegecy] = useState(false);
  const [language, setLanguage] = useState("java");
  const [framework, setFramework] = useState("spring");
  const [database, setDatabase] = useState("postgres");
  const [scheduler, setScheduler] = useState("");
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
  });
  const apiUrl = "application/" + app.id + "/setting/";
  const setNotify = (notify) => {
    setState({ ...state, notify: notify });
  };
  const handleSubmitForm = () => {
    if (
      authType === "" ||
      configType === "" ||
      secretType === ""
      // language === "" ||
      // framework === "" ||
      // database === "" ||
      // scheduler === ""
    ) {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "All are mandetory fields",
          type: "error",
        },
      });
      return;
    }
    if (authType === "OAuth") {
      if (oauthType.length === 0) {
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Select Oauth vendors",
            type: "error",
          },
        });
        return;
      }
    }

    const settingPayload = {
      appId: app.id,
      authMechanism: authType,
      configSystem: configType,
      databaseType: database,
      framework: framework,
      isLegacy: isLagecy,
      language: language,
      oauthVendor: oauthType,
      secretSystem: secretType,
    };
    console.log(settingPayload);

    setState({ ...state, loading: true });

    appTasks
      .post(settingPayload, apiUrl)
      .then((res) => {
        console.log(res);
        if (res) {
          setState({
            loading: false,
            notify: {
              isOpen: true,
              message: "Application setting updated successfully",
              type: "success",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });

    setState({ ...state, loading: false });
  };
  useEffect(() => {
    setAuthType("None");
    setConfigType("Application Properties");
    setSecretType("Application Properties");
  }, []);
  return (
    <React.Fragment>
      <Loading loading={state.loading} />
      <Container component="main" sx={{ flex: 1, py: 5, px: 4 }}>
        <Notification notify={state.notify} setNotify={setNotify} />
        <Paper
          sx={{
            maxWidth: 936,
            margin: "auto",
            overflow: "hidden",
            padding: 5,
          }}
        >
          <Typography
            sx={{ my: 1 }}
            color="text.secondary"
            variant="h3"
            align="center"
          >
            Application Settings
          </Typography>
          <Divider sx={{ marginBottom: 3 }} />
          {/* <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Legacy System
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <FormControl fullWidth>
                <Tooltip arrow title={"Feature available soon.."}>
                  <Switch
                    name="islegacy"
                    checked={false}
                    //checked={isLegacy}
                    onChange={(event) => {
                      setIsLegecy(event.target.checked);
                    }}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ my: 2, mx: 3 }}
                color="text.secondary"
                align="right"
              >
                Authentication Type
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="type-select">
                  Authentication Type
                </InputLabel>
                <Select
                  size="small"
                  labelId="type-select"
                  id="demo-simple-select"
                  label="Authentication Type"
                  value={authType}
                  onChange={(event) => {
                    setAuthType(event.target.value);
                    if (event.target.value === "OAuth") {
                      console.log("OAuth");
                    }
                  }}
                >
                  {authTypeList.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      disabled={item.disabled}
                      selected={item.disabled === false}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              {authType === "OAuth" ? (
                <FormControl fullWidth>
                  <InputLabel size="small" id="type-select">
                    OAuth Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="type-select"
                    id="demo-simple-select"
                    multiple
                    label="OAuth Type"
                    value={oauthType}
                    onChange={(event) => {
                      setOAuthType(event.target.value);
                    }}
                  >
                    {oAuthVendorList.map((item) => (
                      <MenuItem
                        key={item.name}
                        value={item.name}
                        disabled={item.disabled}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ my: 2, mx: 3 }}
                color="text.secondary"
                align="right"
              >
                Configuration Type
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="type-select">
                  Configuration Type
                </InputLabel>
                <Select
                  size="small"
                  labelId="type-select"
                  id="demo-simple-select"
                  label="Configuration Type"
                  value={configType}
                  onChange={(event) => {
                    setConfigType(event.target.value);
                  }}
                >
                  {configTypeList.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      disabled={item.disabled}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Secret System
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="type-select">
                  Secret System
                </InputLabel>
                <Select
                  size="small"
                  labelId="type-select"
                  id="demo-simple-select"
                  label="Configuration Type"
                  value={secretType}
                  onChange={(event) => {
                    setSecretType(event.target.value);
                  }}
                >
                  {secretTypeList.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      disabled={item.disabled}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Language
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="language-group"
                  value={language}
                  onChange={(event) => {
                    setLanguage(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="java"
                    control={<Radio />}
                    label="Java"
                  />
                  <FormControlLabel
                    value="nodejs"
                    disabled
                    control={<Radio />}
                    label="NodeJs"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Frameworks
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="framwork-group"
                  value={framework}
                  onChange={(event) => {
                    setFramework(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="spring"
                    control={<Radio />}
                    label="Spring"
                  />
                  <FormControlLabel
                    value="nodejs"
                    disabled
                    control={<Radio />}
                    label="NodeJs"
                  />
                  <FormControlLabel
                    value="awsserverless"
                    disabled
                    control={<Radio />}
                    label="AWS Serverless"
                  />
                  <FormControlLabel
                    value="googlefunctions"
                    disabled
                    control={<Radio />}
                    label="Google Functions"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Database
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="framwork-group"
                  value={database}
                  onChange={(event) => {
                    setDatabase(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="mysql"
                    control={<Radio />}
                    disabled
                    label="MySQL"
                  />
                  <FormControlLabel
                    value="oracle"
                    disabled
                    control={<Radio />}
                    label="Oracle"
                  />
                  <FormControlLabel
                    value="postgres"
                    control={<Radio />}
                    label="Postgres"
                  />
                  <FormControlLabel
                    value="sqlserver"
                    disabled
                    control={<Radio />}
                    label="SQL Server"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                color="text.secondary"
                align="right"
                sx={{ my: 2, mx: 3 }}
              >
                Scheduclers
              </Typography>
            </Grid>
            <Grid item xs={6} md={9}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="framwork-group"
                  value={scheduler}
                  onChange={(event) => {
                    setScheduler(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="quartz"
                    control={<Radio />}
                    disabled
                    label="Quartz"
                  />
                  <FormControlLabel
                    value="springscheduler"
                    control={<Radio />}
                    disabled
                    label="Spring Scheduler"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: 3 }}>
            <Grid item xs={6} md={4}></Grid>
            <Grid item xs={6} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleSubmitForm()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
