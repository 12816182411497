import React from "react";
import { Controller } from "react-hook-form";
import { TextareaAutosize, FormHelperText } from "@mui/material";

export const FormInputTextArea = ({
  name,
  control,
  label,
  rules,
  onChange,
  minRows = 4,
  style,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <TextareaAutosize
            label={label}
            value={value}
            onChange={onChange}
            minRows={minRows}
            style={style}
          />
          <FormHelperText error={!!error}>
            {error ? error.message : null}
          </FormHelperText>
        </>
      )}
      rules={rules}
    />
  );
};
