import apiProvider from "./provider";

export class ApiCore {
  constructor(options) {
    if (options.getAll) {
      this.getAll = (url = null) => {
        if (url != null) {
          return apiProvider.getAll(url);
        } else {
          return apiProvider.getAll(options.url);
        }
      };
    }

    if (options.getSingle) {
      this.getSingle = (id) => {
        return apiProvider.getSingle(options.url, id);
      };
    }

    if (options.getDomains) {
      this.getDomains = (id) => {
        const url = options.url + "/" + id + options.boUrl;
        return apiProvider.getAll(url);
      };
    }

    if (options.post) {
      this.post = (model, url = null) => {
        if (url != null) {
          return apiProvider.post(url, model);
        } else {
          return apiProvider.post(options.url, model);
        }
      };
    }

    if (options.put) {
      this.put = (model, url = null) => {
        if (url != null) {
          return apiProvider.put(url, model);
        } else {
          return apiProvider.put(options.url, model);
        }
      };
    }

    if (options.patch) {
      this.patch = (model) => {
        return apiProvider.patch(options.url, model);
      };
    }

    if (options.remove) {
      this.remove = (id, url = null) => {
        if (url != null) {
          return apiProvider.remove(url, id);
        } else {
          return apiProvider.remove(options.url, id);
        }
      };
    }
  }
}
