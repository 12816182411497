import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../components/common/Loading";
import Notification from "../components/common/Notification";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { red } from "@mui/material/colors";
import { appTasks } from "../services/api/apps";
import {
  TableBody,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  AppBar,
  Grid,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Container,
  Box,
  Divider,
} from "@mui/material";
import ConfirmDialog from "../components/common/ConfirmDialog";

export default function ActionSteps() {
  const location = useLocation();
  const action = location.state;
  console.log(action.id);
  const actionUrl = "application/" + action.appId + "/actions/" + action.id;
  const integrationUrl = "metadata/integrations";
  console.log("action", action);
  // console.log("action Name ", action.action.steps.step);

  const [integration, setIntegration] = useState("");
  const [integrationlist, setIntegrationList] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [formValues, setFormValues] = useState([
    { stepParamName: "", stepParamValues: "" },
  ]);

  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
  });

  const setNotify = (notify) => {
    console.log("Set nogify", notify);
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    setState({ ...state, loading: false });
  };

  useEffect(() => {
    setState({ ...state, loading: true });
    appTasks
      .getAll(integrationUrl)
      .then((res) => {
        if (res) {
          setIntegrationList(res);
          setState({ ...state, loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false });
      });
  }, []);

  const deleteStep = (id) => {
    console.log(id);
  };
  const useGetAllActionSteps = action.action.steps?.step.map((step, index) => {
    return (
      <TableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>{step.stepNumber}</TableCell>
        <TableCell>{step.integrationType}</TableCell>
        <TableCell>
          <IconButton
            sx={{ alignContent: "flex-end" }}
            onClick={() => {
              deleteStep(`${step.stepNumber}`);
            }}
          >
            <DeleteForeverIcon sx={{ color: red[500] }} />
          </IconButton>
        </TableCell>
        <TableCell>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {step.stepParams?.stepParam.map((params, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{params.stepParamName}</TableCell>
                      <TableCell>{params.stepParamValues}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableCell>
      </TableRow>
    );
  });

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log(formValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { stepParamName: "", stepParamValues: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    if (integration === "") {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "Integration type is required.",
          type: "error",
        },
        loading: false,
      });
    }
    console.log(formValues);
    let newAction = { ...action };
    console.log(newAction);
    console.log(JSON.stringify(newAction));
    let stepNbr = 1;

    if (newAction.action.steps?.step) {
      stepNbr = newAction.action.steps?.step.length + 1;
    }
    console.log(newAction.action.steps);
    const newStep = {
      integrationType: integration,
      stepNumber: stepNbr,
      stepParams: { stepParam: formValues },
    };
    if (newAction.action.steps === null) {
      newAction.action.steps = { step: [newStep] };
    } else {
      newAction.action.steps.step.push(newStep);
    }
    console.log(newAction);
    appTasks
      .put(newAction, actionUrl)
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New attribute created");
          let newActions = [...action];
          newActions.push(res);
          // setAllActions(newActions);
          setFormValues([
            ...formValues,
            { stepParamName: "", stepParamValues: "" },
          ]);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "New attribute created successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });
  };

  return (
    <React.Fragment>
      <Container component="main" sx={{ flex: 1, py: 5, px: 4 }}>
        <Loading loading={state.loading} setLoading={setLoading} />
        <Notification notify={state.notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
          <Typography
            sx={{ my: 2, mx: 3, fontWeight: 100 }}
            variant="h3"
            align="center"
          >
            {action.businessObjectName} : {action.action.actionType}
          </Typography>
          <Divider />
          <Box
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              padding: 3,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={2}></Grid>
                <Grid item xs={6} md={8}>
                  <Autocomplete
                    size="small"
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    disablePortal
                    id="combo-box-demo"
                    options={integrationlist}
                    fullWidth
                    getOptionDisabled={(option) => !option.isActive}
                    onChange={(event, newValue) => {
                      console.log(newValue?.code);
                      setIntegration(newValue?.code);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Integrations" />
                    )}
                  />
                </Grid>
              </Grid>

              {/* <InputLabel id="l-type-select">Integration Type</InputLabel>
                <Select
                  sx={{ minWidth: 180, marginBottom: 2 }}
                  labelId="l-type-select"
                  label="Integration Type"
                  value={integration}
                  onChange={(event) => {
                    setIntegration(event.target.value);
                  }}
                  placeholder="Integration Type"
                >
                  <MenuItem value="" selected>
                    <em>Select Integration</em>
                  </MenuItem>
                  {integrations.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select> */}

              {formValues.map((element, index) => (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  style={{ display: "flex", marginTop: 5 }}
                >
                  <Grid item xs={6} md={2}></Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      size="small"
                      fullWidth
                      name="stepParamName"
                      placeholder="Enter name"
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                      label="Param Name"
                      variant="outlined"
                      value={element.stepParamName || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      size="small"
                      fullWidth
                      name="stepParamValues"
                      placeholder="Enter name"
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                      label="Param Value"
                      variant="outlined"
                      value={element.stepParamValues || ""}
                    />
                  </Grid>
                  {index ? (
                    <Grid item xs={6} md={4}>
                      <IconButton
                        sx={{ alignContent: "flex-end" }}
                        onClick={() => {
                          removeFormFields(index);
                        }}
                      >
                        <DeleteForeverIcon sx={{ color: red[500] }} />
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              ))}

              <Grid
                container
                spacing={2}
                style={{ display: "flex", marginTop: 5 }}
              >
                <Grid item xs={6} md={2}></Grid>
                <Grid item xs={3} md={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => addFormFields()}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs={3} md={4}>
                  <Button fullWidth type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <TableContainer justify="center">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Step</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Delete</TableCell>
                    <TableCell>Parameter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!state.loading ? (
                    action.action.steps?.step &&
                    action.action.steps?.step.length > 0 ? (
                      <>{useGetAllActionSteps}</>
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell colSpan={4} align="center">
                          <Typography
                            color="text.secondary"
                            align="center"
                            variant="body1"
                          >
                            No Steps
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
