import React from "react";
import { ListItem, Typography } from "@mui/material";
import LensBlurIcon from "@mui/icons-material/LensBlur";

export default function Logo() {
  return (
    <ListItem
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: 20,
      }}
    >
      <Typography
        fontWeight="200"
        variant="h2"
        color="primary"
        fontFamily={"Ubuntu Mono"}
        sx={{
          marginLeft: -2,
          paddingTop: 0,
        }}
      >
        Appiflow
      </Typography>
      <LensBlurIcon fontSize="medium" color="primary" />
    </ListItem>
  );
}
