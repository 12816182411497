import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Link,
  BottomNavigation,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Copyright from "../../components/common/Copyright";
import Logo from "../../components/common/Logo";
import { ThemeProvider } from "@mui/material/styles";
import { PureLightTheme } from "../../components/common/PureLightTheme";
import api from "../../services/api/api";
import { useMutation, useQueryClient } from "react-query";
import Loading from "../../components/common/Loading";
import Notification from "../../components/common/Notification";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

function ConfirmEmail() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  let code = params.get("token");
  const queryClient = useQueryClient();

  const checkToken = () => {
    return api.postData("user/confirm_registration?token=" + code);
  };
  const { mutate, isLoading } = useMutation(checkToken, {
    onSuccess: async (response) => {
      const status = response.status;
      console.log(status);
      if (status === 200) {
        setSuccess(true);
        return;
      }
    },
    onError: async (error) => {
      console.log(
        error.response.status,
        "there was an error",
        error.response.data
      );
      if (error.response.status === 401) {
        setFailure(true);
      }
      if (error.response.stats === 500) {
        setNotify({
          isOpen: true,
          message: "Something went wrong. Please try again!k",
          type: "error",
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("createUser");
    },
  });

  useEffect(() => {
    console.log("code " + code);
    mutate();
  }, [code]);

  return (
    <>
      <ThemeProvider theme={PureLightTheme}>
        <Loading loading={isLoading} />
        <Notification notify={notify} setNotify={setNotify} />
        <MainContent>
          <Container
            maxWidth="xs"
            sx={{ backgroundColor: "#fff", borderRadius: 1, minHeight: 300 }}
          >
            <Box textAlign="center">
              <Logo />
              <Divider sx={{ my: 1 }} />
              {success ? (
                <Typography
                  variant="h3"
                  sx={{ mt: 4, mb: 2, my: 5, fontWeight: 100}}
                  display={success}
                >
                  Thank you for confirming email. Please click to{" "}
                  <Link href="/login" variant="h3">
                    Login
                  </Link>
                </Typography>
              ) : (
                <></>
              )}
              {failure ? (
                <Typography
                  color="red"
                  variant="h3"
                  sx={{ mt: 4, mb: 2, my: 5, fontWeight: 100 }}
                  display={failure}
                >
                  Something is wrong! Please check the link in the email or{" "}
                  <Link
                    href="/login?l=signup"
                    variant="h3"
                    xs={{ fontWeight: 100 }}
                  >
                    signup
                  </Link>{" "}
                  again
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Container>
          <BottomNavigation sx={{ backgroundColor: "transparent" }}>
            <Copyright />
          </BottomNavigation>
        </MainContent>
      </ThemeProvider>
    </>
  );
}

export default ConfirmEmail;
