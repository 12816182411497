import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { appTasks } from "../services/api/apps";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Switch,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../components/common/Loading";
import Notification from "../components/common/Notification";
import { useLocation } from "react-router-dom";
import BoxShow from "../components/common/BoxShow";
import NewBoxShow from "../components/common/NewBoxShow";
import ConfirmDialog from "../components/common/ConfirmDialog";
import { DialogBox } from "../components/common/DialogBox";
import BODetails from "./BODetails";
import { toCamelCaseFirstUpper } from "../utils/stringUtils";
import { useNavigate } from "react-router-dom";
import useAuth from "../components/auth/useAuth";

function SimpleDialog(props) {
  const {
    onClose,
    open,
    domain,
    setDomain,
    createDomain,
    isCached,
    setIsCached,
    tablename,
    setTableName,
    isLegacy = false,
  } = props;

  return (
    <Dialog maxWidth={"xs"} onClose={onClose} open={open}>
      <DialogTitle>
        <Typography variant="h4" color="text.primary" align="center">
          Create New Business Object
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ paddingTop: 30 }}>
            <TextField
              size="small"
              autoComplete="off"
              fullWidth
              placeholder="Business Object Name"
              onChange={(event) => {
                //setDomain(event.target.value);

                let name = toCamelCaseFirstUpper(event.target.value);
                console.log(name);
                setDomain(name);
              }}
              label="Business Object Name"
              variant="outlined"
              value={domain}
            />
            <Typography fontSize={12}>
              * Space and special characters are not allowed. <br />
              Please use camel case.Ex. User, UserGroup
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} display={isLegacy ? "block" : "none"}>
            <TextField
              size="small"
              fullWidth
              placeholder="Table Name"
              onChange={(event) => {
                setTableName(event.target.value);
              }}
              label="Table Name"
              variant="outlined"
              value={tablename}
            />
          </Grid>
          {/* <Grid item xs={6} md={6}>
            <Typography color="text.secondary" align="right" sx={{ my: 1 }}>
              Cached
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <Switch
                checked={isCached}
                onChange={(event) => setIsCached(event.target.checked)}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Button variant="contained" fullWidth onClick={createDomain}>
              Create
            </Button>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function BOList() {
  const location = useLocation();
  let appID = location.state?.appId;
  const navigate = useNavigate();
  const { logout } = useAuth();
  if (appID === undefined) {
    const app = JSON.parse(localStorage.getItem("app"));
    appID = app.id;
  }
  const [open, setOpen] = useState(false);
  const [domains, setAllDomains] = useState([]);
  const [domain, setDomain] = useState("");
  const [isCached, setIsCached] = useState(false);
  const [boSetting, setBOsetting] = useState(false);
  const [tableName, setTableName] = useState("");
  const [boid, setBoID] = useState(null);
  const [boName, setBoName] = useState("");
  const [selectedBo, setSelectedBO] = useState({ id: "", name: "" });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
  });
  const setNotify = (notify) => {
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    setState({ ...state, loading: false });
  };

  const redirect = () => {
    logout().then(() => {
      localStorage.clear();
      navigate("/login");
    });
  };

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .getDomains(appID)
      .then((res) => {
        if (res) {
          console.log(res);
          setAllDomains(res);
          setState({
            ...state,
            loading: false,
          });
        }
        console.log("domain loading done");
      })
      .catch((error) => {
        console.log("domain loading error");
        console.log(error);
        if (error.status === 401) {
          redirect();
        }
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, [appID]);

  const deleteDomain = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this business object?",
      subTitle: "You will loose all your business object related data",
      onConfirm: () => {
        console.log("Yes");
        onDelete(id);
      },
    });
    console.log("Delete BO ", id);
  };
  const onDelete = (id) => {
    setState({ ...state, loading: true });
    const url = "application/" + appID + "/businessObject/" + id;
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    appTasks
      .remove(id, url)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newdomains = [...domains];
          const domainlist = newdomains.filter((domain) => domain.id != id);
          console.log(domainlist);
          setAllDomains(domainlist);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Application is deleted successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      });
  };

  const createDomain = async () => {
    handleClose();
    console.log("Create New Domain clicked" + isCached);
    if (domain === "" || domain === undefined) {
      console.log("Domain name is empty");
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "Please enter Business Object name",
          type: "error",
        },
      });
      return;
    }
    console.log(domain);
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .post(
        { businessObjectName: domain, appId: appID, isCached: isCached },
        "application/" + appID + "/businessObject"
      )
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New BO Created");
          let newdomains = [...domains];
          newdomains.push(res);
          setAllDomains(newdomains);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "New Business Object created successfully",
              type: "success",
            },
          });
          setDomain("");
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });
  };

  const addNewBo = () => {
    console.log("Add New BO");
    setOpen(true);
  };
  const boSettings = (id, name) => {
    console.log("BO ID" + id + " name" + name);
    setSelectedBO({ id: id, name: name });
    setBOsetting(true);
  };

  const updateSetting = (id) => {
    console.log("Update Settings to id " + id + " Name " + selectedBo.name);
  };
  const settingForm = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              size="small"
              fullWidth
              placeholder="Business Object Name"
              label="Business Object Name"
              variant="outlined"
              value={selectedBo.name}
              onChange={(event) => {
                setSelectedBO({ ...selectedBo, name: event.target.value });
              }}
            />
          </Grid>
          {/* <Grid item xs={6} md={6}>
            <Typography color="text.secondary" align="right" sx={{ my: 1 }}>
              Cached
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <Switch checked={isCached} />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              onClick={() => updateSetting(`${selectedBo.id}`)}
            >
              Update Business Object
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };
  const showBOSettingDialog = () => {
    return (
      <DialogBox
        isOpen={boSetting}
        onClose={handleClose}
        maxWidth="xs"
        title={"Business Object Setting"}
        childern={settingForm()}
      ></DialogBox>
    );
  };

  const showBODetails = (id, name) => {
    console.log(name);
    setBoID(id);
    setBoName(name);
  };
  const useGetAllDomains = domains.map((domain, i) => {
    return (
      <BoxShow
        key={i}
        //to={"/dashboard/bodetails"}
        onClick={() =>
          showBODetails(`${domain.id}`, `${domain.businessObjectName}`)
        }
        stateval={{
          id: `${domain.id}`,
          businessObjectName: domain.businessObjectName,
        }}
        title={domain.businessObjectName}
        subtitle={domain.tableName ? domain.tableName : ""}
        deleteme={deleteDomain}
        deleteid={domain.id}
        isSetting={true}
        settingFunc={boSettings}
        info={domain.isCached ? "Cached" : ""}
        tooltip={"Remove " + domain.businessObjectName + " business object"}
      />
    );
  });

  const handleClose = () => {
    setOpen(false);
    setBOsetting(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={7} style={{ height: "90vh" }}>
          <Loading loading={state.loading} setLoading={setLoading} />
          <Notification notify={state.notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <SimpleDialog
            open={open}
            onClose={handleClose}
            domain={domain}
            setDomain={setDomain}
            createDomain={createDomain}
            isCached={isCached}
            setIsCached={setIsCached}
          />
          {showBOSettingDialog()}

          <Grid container spacing={0} alignItems="left" justifyContent="left">
            <NewBoxShow
              to={"/dashboard/bodetails"}
              subtitle={"Add Business Object"}
              createnew={true}
              addfunct={addNewBo}
              tooltip={"Create New Business Object"}
            />
            {domains && domains.length === 0 ? (
              state.loading === true ? (
                <></>
              ) : (
                // <Grid item xs={12}>
                //   <Typography
                //     variant="body1"
                //     color="text.primary"
                //     align="left"
                //   >
                //     No business object found. Please create a new business
                //     object.
                //   </Typography>
                // </Grid>
                <></>
              )
            ) : (
              useGetAllDomains
            )}
          </Grid>
          {/* </Paper> */}
        </Grid>
        <Grid item xs={5} style={{ height: "90vh", padding: 0 }}>
          {boid ? <BODetails key={boid} id={boid} boname={boName} /> : <></>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
