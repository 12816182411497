import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  Typography,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Grid,
  Divider,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";

import { appTasks } from "../services/api/apps";
import { ThemeProvider } from "@mui/material/styles";
import Copyright from "../components/common/Copyright";
import ConfirmDialog from "../components/common/ConfirmDialog";
import { PureLightTheme } from "../components/common/PureLightTheme";
import BoxShow from "../components/common/BoxShow";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import useAuth from "../components/auth/useAuth";
import { useNavigate } from "react-router-dom";
import Logo from "../components/common/Logo";
import LogoutIcon from "@mui/icons-material/Logout";

export default function AppList() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
    name: "",
  });

  const [apps, setApps] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const _doLogout = () => {
    logout().then(() => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const loadAllApps = () => {
    console.log("loading apps");
    setState({ ...state, loading: true });
    appTasks
      .getAll()
      .then((res) => {
        console.log(res);
        if (res) {
          setState({ ...state, loading: false });
          setApps(res);
        }
        console.log("apps loading done");
        console.log(apps);
      })
      .catch((error) => {
        console.log(error);
        if (error)
          if (error.status === 401) {
            _doLogout();
          }
        if (error.response)
          if (error.response.status === 401) {
            _doLogout();
          }

        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  };
  useEffect(() => {
    loadAllApps();
  }, []);

  const createApp = async () => {
    console.log("Name ", state.name);
    if (state.name === "" || state.name === undefined) {
      console.log("Application name is empty");
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "Please enter application name",
          type: "error",
        },
      });

      return;
    }
    setState({ ...state, loading: true });

    //const response = await api.post("application/", { appName: name });
    appTasks
      .post({ appName: state.name })
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New App Created");
          let newapps = [...apps];
          newapps.push(res);
          setApps(newapps);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "New Application created successfully",
              type: "success",
            },
            name: "",
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });
  };

  const onDelete = (appid) => {
    console.log("App to delete", appid);

    setState({ ...state, loading: true });
    const url = "application/" + appid;
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    appTasks
      .remove(appid, url)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newapps = [...apps];
          const applist = newapps.filter((app) => app.id != appid);
          console.log(applist);
          setApps(applist);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Application is deleted successfully",
              type: "success",
            },
            name: "",
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      });
  };

  const deleteApp = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this application?",
      subTitle: "You will loose all your application related data",
      onConfirm: () => {
        console.log("Yes");
        onDelete(id);
      },
    });
  };

  const setNotify = (notify) => {
    console.log("Set nogify", notify);
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    console.log("Set loading..", loading);
    setState({ ...state, loading: false });
  };

  const useGetAllApps = apps.map((app, i) => {
    // console.log(app);
    return (
      <BoxShow
        key={i}
        to={"/dashboard/bolist"}
        stateval={{ appId: `${app.id}` }}
        title={app.appName}
        // subtitle={app.appName}
        deleteme={deleteApp}
        deleteid={app.id}
        tooltip={"Remove " + app.appName + " application"}
      />
    );
  });

  return (
    <ThemeProvider theme={PureLightTheme}>
      <CssBaseline />
      <Loading loading={state.loading} setLoading={setLoading} />
      <Notification notify={state.notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Box sx={{ display: "flex", minHeight: "100vh" }} color="primary">
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Toolbar color="primary" sx={{ height: 70, textAlign: "center" }}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12}>
                <Logo />
                <Tooltip arrow title={"Logout"}>
                  <IconButton
                    color="primary"
                    sx={{
                      marginRight: 10,
                      float: "right",
                      my: -5,
                    }}
                    onClick={() => _doLogout()}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                {/* <Typography
                  fontWeight="linear"
                  color="primary"
                  variant="body2"
                ></Typography> */}
              </Grid>
            </Grid>
            {/*<List sx={{ alignSelf: "center" }}>
               <ListItem>
                <Typography
                  fontWeight="linear"
                  variant="h1"
                  fontFamily={"Ubuntu Mono"}
                  sx={{
                    marginLeft: -2,
                  }}
                >
                  Appiflow
                </Typography>
                <LensBlurIcon />
              </ListItem>
              <Typography
                variant="caption"
                color="white"
                display="block"
                gutterBottom
              >
                Rapid API
              </Typography> 
            </List> */}
          </Toolbar>
          <Divider />
          {/* <Header onDrawerToggle={handleDrawerToggle} heading="AppiFlow" /> */}
          <Box
            component="main"
            sx={{ flex: 1, py: 5, px: 4, bgcolor: "#eaeff1" }}
          >
            <Paper
              sx={{
                maxWidth: 1000,
                margin: "auto",
                overflow: "hidden",
                backgroundColor: "#009be5",
                border: "1px solid #d6d6d6",
              }}
              elevation={0}
            >
              <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              >
                <Toolbar>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    style={{
                      paddingLeft: 60,
                      paddingRight: 60,
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Grid item md style={{ paddingLeft: 60, paddingRight: 60 }}>
                      <Typography
                        variant="h2"
                        color="text.primary"
                        align="center"
                        sx={{ fontWeight: 100 }}
                      >
                        Applications
                      </Typography>
                      <br />
                      <OutlinedInput
                        type="text"
                        fullWidth
                        placeholder="Application Name"
                        onChange={(event) => {
                          setState({ ...state, name: event.target.value });
                        }}
                        variant="outlined"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ paddingRight: 1 }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => createApp()}
                            >
                              <AddIcon /> Create New Application
                            </Button>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>

              <Grid
                container
                spacing={0}
                alignItems="left"
                justifyContent="left"
                style={{ paddingBottom: 100, backgroundColor: "#F5F7F9" }}
              >
                {apps && apps.length > 0 ? (
                  useGetAllApps
                ) : (
                  // <Grid item>
                  //   <Typography
                  //     variant="h6"
                  //     color="text.primary"
                  //     align="center"
                  //   >
                  //     No application found. Please create a new applciation.
                  //   </Typography>
                  // </Grid>
                  <></>
                )}
              </Grid>
            </Paper>
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
