import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./components/auth/useAuth";
import { BrowserRouter as Router } from "react-router-dom";

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </React.StrictMode>
  </HelmetProvider>,

  document.getElementById("root")
);
