import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const IconButtonSuccess = styled(IconButton)(
  ({ theme }) => `
    //  background: ${theme.colors.secondary.lighter};
     color: ${theme.colors.primary.main};
     padding: ${theme.spacing(0.5)};
     border: 1px solid ${theme.colors.alpha.black[30]};

     &:hover {
      background: ${(theme.colors.secondary.lighter, 0.4)};
     }
`
);

const CardCc = styled(Card)(
  ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[30]};
    //  background: ${theme.colors.primary.lighter};
     box-shadow: none;
     border-radius: 3px;
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        box-shadow: none;
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`
);

export default function BoxShow(props) {
  const { subtitle, tooltip = "", addfunct } = props;
  return (
    <Box p={3} style={{ paddingTop: 30, textAlign: "center" }}>
      <Grid container spacing={0}>
        <Grid item xs={2} sm={12}>
          <CardAddAction
            sx={{
              px: 1,
              pt: 2,
              pb: 1,
              minHeight: 100,
              minWidth: 200,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  textAlign: "center",
                  paddingTop: 1,
                }}
              >
                <Tooltip arrow title={tooltip} sx={{ marginBottom: 1 }}>
                  <IconButtonSuccess onClick={addfunct}>
                    <AddIcon fontSize="small" />
                  </IconButtonSuccess>
                </Tooltip>

                <Typography variant="h4" sx={{ fontWeight: "100" }}>
                  {subtitle}{" "}
                </Typography>
              </Box>
            </CardContent>
          </CardAddAction>
        </Grid>
      </Grid>
    </Box>
  );
}
