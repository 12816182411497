import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Divider } from "@mui/material";
import { DialogBox } from "../../components/common/DialogBox";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Loading from "../../components/common/Loading";
import Notification from "../../components/common/Notification";
import { FormRadio, FormInputTextArea } from "../../components/MUI";
import api from "../../services/api/api";

export default function Feedback(props) {
  const { showFb } = props;
  console.log("Show FB", showFb);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const { handleSubmit, control, reset } = useForm();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const handleClose = () => {
    setShowFeedbackDialog(false);
    if (showFb === true) props.onCloseFB(false);
  };

  const submitFeedback = (data) => {
    console.log("Feedback", data);
    mutate(data);
  };
  useEffect(() => {
    setShowFeedbackDialog(showFb);
  }, [showFb]);
  const postFeedback = async (data) => {
    // console.log(data);
    let postdata = {
      question1: 1,
      answer1: data.answer1,
      question2: 2,
      answer2: data.answer2,
      question3: 3,
      answer3: data.answer3,
      comments: data.comment,
    };
    console.log(JSON.stringify(postdata));
    if (showFb === true) props.onCloseFB(false);
    return await api.post("feedback", postdata);
    //return;
  };

  const { mutate, isLoading } = useMutation(postFeedback, {
    onSuccess: async (response) => {
      const data = response.data;
      console.log(data);

      setNotify({
        isOpen: true,
        message: "Thank you for your valuable feedback",
        type: "success",
      });
      reset(null);
      return;
    },
    onError: async (error) => {
      console.log(
        error.response.status,
        "there was an error",
        error.response.data
      );
      setNotify({
        isOpen: true,
        message: error.response.data.details,
        type: "error",
      });
    },
  });
  const answerList1 = [
    { label: "Strongly Agree", value: "Strongly Agree" },
    { label: "Agree", value: "Agree" },
    { label: "Weakly Agree", value: "Weakly Agree" },
  ];
  const answerList2 = [
    { label: "Highly", value: "Highly" },
    { label: "Likely", value: "Likely" },
    { label: "Less Likely", value: "Less Likely" },
  ];
  const answerList3 = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "NO" },
  ];
  const feedbackForm = () => {
    return (
      <>
        <Loading loading={isLoading} />

        <form onSubmit={handleSubmit(submitFeedback)}>
          <Notification notify={notify} setNotify={setNotify} />
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="text.secondary" align="left">
                1) *Do you think will Appiflow help in building enterprise
                applications?
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRadio
                name="answer1"
                groupName="answer1"
                groupList={answerList1}
                rules={{ required: "Required" }}
                control={control}
              />
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="text.secondary" align="left">
                2) *Do you plan to use Appiflow in your current work?
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRadio
                name="answer2"
                groupName="answer2"
                groupList={answerList2}
                rules={{ required: "Required" }}
                control={control}
              />
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="text.secondary" align="left">
                3) *Do you recommend Appiflow to your friends or colleagues?
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRadio
                name="answer3"
                groupName="answer3"
                groupList={answerList3}
                rules={{ required: "Required" }}
                control={control}
              />
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="text.secondary" align="left">
                Please provide your feedback or comments
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInputTextArea
                name="comment"
                minRows={4}
                control={control}
                style={{ width: "100%" }}
              />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                align="left"
              >
                * is mandetory
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={3}>
              <Button fullWidth variant="contained" onClick={() => reset()}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button fullWidth type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
        </form>
      </>
    );
  };
  const showFeedbackModal = () => {
    return (
      <DialogBox
        isOpen={showFeedbackDialog}
        onClose={handleClose}
        maxWidth="sm"
        title={"Your feedback is valuable to us!"}
        childern={feedbackForm()}
      ></DialogBox>
    );
  };
  const showFeedback = () => {
    console.log("Show Feedback");
    setShowFeedbackDialog(true);
  };
  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: "200px",
          marginBottom: 2,
          fontWeight: "bold",
          borderRadius: 4,
        }}
        onClick={() => showFeedback()}
      >
        Feedback
      </Button>
      {showFeedbackModal()}
    </>
  );
}
