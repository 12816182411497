export function toCamelCaseFirstUpper(s) {
  return s
    .replace(/\w\S*/g, function (t) {
      console.log(t);
      if (isNaN(t.charAt(0))) return t.charAt(0).toUpperCase() + t.substr(1);
      return "";
    })
    .replace(/[^a-zA-Z0-9]/g, "")
    .replace(/ /g, "");
}

export function toCamelCaseFirstLower(s) {
  return s
    .replace(/\w\S*/g, function (t) {
      console.log(t);
      if (isNaN(t.charAt(0))) return t.charAt(0).toLowerCase() + t.substr(1);
      return "";
    })
    .replace(/[^a-zA-Z0-9]/g, "")
    .replace(/ /g, "");
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}
