import Dashboard from "./pages/Dashboard";
import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Status404 from "./pages/NotFound";
import Login from "./pages/Login";
import AppList from "./pages/AppList";
import BODetails from "./pages/BODetails";
import Actions from "./pages/Actions";
import Relations from "./pages/Relations";
import Deployment from "./pages/Deployment";
import BOList from "./pages/BOList";
import ActionSteps from "./pages/ActionSteps";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";
import AppSettings from "./pages/AppSettings";
import { QueryClient, QueryClientProvider } from "react-query";
import useAuth from "./components/auth/useAuth";
import PasswordReset from "./pages/auth/PasswordReset";
import ConfirmEmail from "./pages/auth/Confirmemail";
import Static from "./pages/Static";

const queryClient = new QueryClient();
function RequireAuth({ children }) {
  const { authed } = useAuth();
  console.log("Authed ", authed);
  return authed === true ? children : <Navigate to="/login" replace />;
}

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path="/home"
            element={<Navigate to={"/static/index.html"} />}
          ></Route>
          <Route path="/" element={<Static />} />
          <Route path="/passreset" element={<PasswordReset />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Status404 />} />
          <Route
            path="/apps"
            element={
              <RequireAuth>
                <AppList />
              </RequireAuth>
            }
          />

          <Route
            path="/dashboard/"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          >
            <Route path="appsettings" element={<AppSettings />} />
            <Route path="bolist" element={<BOList />} />
            <Route path="bodetails" element={<BODetails />} />
            <Route path="relations" element={<Relations />} />
            <Route path="actions" element={<Actions />} />
            <Route path="actionsteps" element={<ActionSteps />} />
            <Route path="deployment" element={<Deployment />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
