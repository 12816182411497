import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Card,
  List,
  ListItem,
  ListItemText,
  CardActions,
  CardContent,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Label from "./Label";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     color: ${theme.colors.error.main};
     padding: ${theme.spacing(0.5)};
     border: 1px solid ${theme.colors.alpha.black[30]};
     &:hover {
      background: ${(theme.colors.error.lighter, 0.4)};
     }
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
     color: ${theme.colors.primary.main};
     border: 1px solid ${theme.colors.alpha.black[30]};
     padding: ${theme.spacing(0.5)};
     margin:${theme.spacing(0.5)};

     &:hover {
      background: ${(theme.colors.primary.lighter, 0.4)};
     }
`
);

const CardCc = styled(Card)(
  ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[30]};
    //  background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     border-radius: 3px;
`
);

export default function BoxShow(props) {
  const {
    to,
    stateval,
    title,
    subtitle = "",
    deleteme,
    deleteid,
    tooltip = "",
    info = "",
    info2 = "",
    info3 = "",
    isSetting = false,
    settingFunc,
    onClick,
  } = props;
  return (
    <Box p={3} style={{ paddingTop: 30 }}>
      <Grid container spacing={1}>
        <Grid item xs={1} sm={12}>
          <CardCc
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              minHeight: 100,
              minWidth: 200,
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>
                {to ? (
                  <Typography
                    variant="h3"
                    fontWeight="normal"
                    color="ButtonText"
                    component={Link}
                    to={to}
                    onClick={onClick}
                    state={stateval}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {title}
                  </Typography>
                ) : (
                  <Typography
                    component={Button}
                    variant="h4"
                    fontWeight="100"
                    color="ButtonText"
                    onClick={onClick}
                    state={stateval}
                    style={{
                      textDecoration: "none",
                      padding: 1,
                      TextareaAutosize: true,
                      textAlign: "left",
                    }}
                  >
                    {title}
                  </Typography>
                )}
                <Typography variant="subtitle2">{subtitle} &nbsp;</Typography>
              </Box>

              <List disablePadding>
                <ListItem disablePadding>
                  {info !== "" ? (
                    <ListItemText
                      secondary={<Label color="warning">{info}</Label>}
                    />
                  ) : (
                    <></>
                  )}
                  {info2 !== "" ? (
                    <ListItemText
                      secondary={<Label color="info">{info2}</Label>}
                    />
                  ) : (
                    <></>
                  )}
                </ListItem>
              </List>
            </Box>

            <Box
              // disableSpacing
              // sx={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-between",
              //   // marginTop: "16px",
              // }}
              pt={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ListItemText
                sx={{ float: "left" }}
                secondary={
                  info3 !== "" ? <Label color="primary">{info3}</Label> : <></>
                }
              />
              {isSetting ? (
                <Tooltip arrow title={"Business Object Setting"}>
                  <IconButtonPrimary
                    onClick={() => {
                      settingFunc(`${deleteid}`, title);
                    }}
                    edge="end"
                  >
                    <MoreHorizSharpIcon fontSize="small" />
                  </IconButtonPrimary>
                </Tooltip>
              ) : (
                <></>
              )}
              <Tooltip arrow title={tooltip}>
                <IconButtonError
                  onClick={() => {
                    deleteme(`${deleteid}`);
                  }}
                >
                  <DeleteTwoToneIcon fontSize="small" />
                </IconButtonError>
              </Tooltip>
            </Box>
          </CardCc>
        </Grid>
      </Grid>
    </Box>
  );
}
