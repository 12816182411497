import axios from "axios";

axios.defaults.baseURL = "https://lcappbackend.herokuapp.com/lowcode/";
const access_token = JSON.parse(localStorage.getItem("access_token"));
const axiosApi = axios.create({
  baseURL: "https://lcappbackend.herokuapp.com/lowcode/",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  },
});

const axiosWitouTokenApi = axios.create({
  baseURL: "https://lcappbackend.herokuapp.com/lowcode/",
  headers: {
    "Content-Type": "application/json",
  },
});

const postData = async (resource, data) => {
  const response = await axiosWitouTokenApi.post(resource, data);
  return response;
};

const post = async (resource, data) => {
  const response = await axiosApi.post(resource, data);
  return response.data;
};

const get = async (resource) => {
  const response = await axiosApi.get(resource);
  return response;
};

const download = async (resource) => {
  const response = await axiosApi.get(resource, {
    responseType: "arraybuffer",
  });
  return response;
};

export default { post, postData, get, download };
