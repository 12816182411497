import { Box, Typography, Container, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Status404() {
  const [show, setShow] = useState(false);
  console.log(window.location.pathname);
  useEffect(() => {
    if (window.location.pathname === "/static/index.html") {
      window.location.reload(false);
    } else {
      setShow(true);
    }
  }, []);
  return (
    <>
      {show ? (
        <MainContent>
          <Container
            maxWidth="xs"
            sx={{ backgroundColor: "#fff", borderRadius: 1, minHeight: 500 }}
          >
            <Box textAlign="center">
              <Typography variant="h1" sx={{ my: 6, fontSize: 60 }}>
                404!
              </Typography>

              <Typography variant="h3" sx={{ my: 15 }}>
                The page you were looking for doesn't exist.
              </Typography>
            </Box>
            <Container
              maxWidth="sm"
              sx={{ textAlign: "center", marginTop: 10 }}
            >
              Please visit &nbsp;
              <Link href="/login" variant="h4" onClick={() => {}}>
                Login
              </Link>
            </Container>
          </Container>
        </MainContent>
      ) : (
        <></>
      )}
    </>
  );
}

export default Status404;
