import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Link,
  Button,
  BottomNavigation,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Copyright from "../../components/common/Copyright";
import Notification from "../../components/common/Notification";
import Logo from "../../components/common/Logo";
import centerForm from "../../styles/formCenter";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/MUI";
import { ThemeProvider } from "@mui/material/styles";
import { PureLightTheme } from "../../components/common/PureLightTheme";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

function PasswordReset() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const classes = centerForm();
  const { handleSubmit, control, reset } = useForm();

  // const handleLoginState = (state) => {
  //   setNotify({
  //     isOpen: true,
  //     message: "Account is created. Please check your email for confirmation. ",
  //     type: "success",
  //   });
  // };
  const onSubmit = (data) => {
    console.log(data);
    if (data.password !== data.confipassword) {
      setNotify({
        isOpen: true,
        message: "Confirmation password is not matching",
        type: "error",
      });
      return;
    }

    // mutate(login);
  };

  return (
    <>
      <ThemeProvider theme={PureLightTheme}>
        <Notification notify={notify} setNotify={setNotify} />
        <MainContent>
          <Container
            maxWidth="xs"
            sx={{ backgroundColor: "#fff", borderRadius: 1, minHeight: 500 }}
          >
            <Box textAlign="center">
              <Logo />
              <Divider sx={{ my: 1 }} />
              <Typography variant="h2" sx={{ mt: 4, mb: 2, fontWeight: 100 }}>
                Password Reset
              </Typography>
            </Box>
            <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
              <FormInputText
                name="password"
                label="Password"
                control={control}
                rules={{
                  required: "Email required",
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,30}$/,
                    message:
                      "Password must be 8 characters. 1 Upper case. 1 special character. 2 numbers ",
                  },
                }}
                type="password"
              />
              <FormInputText
                name="confipassword"
                label="Cofirmation Password"
                control={control}
                rules={{ required: "Confirmation Password required" }}
                type="password"
              />
              <div>
                <Button variant="contained" onClick={() => reset()}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </div>
              Already signed up! please &nbsp;
              <Link href="/login" variant="body1">
                Login
              </Link>
            </form>
          </Container>
          <BottomNavigation sx={{ backgroundColor: "transparent" }}>
            <Copyright />
          </BottomNavigation>
        </MainContent>
      </ThemeProvider>
    </>
  );
}

export default PasswordReset;
