import React, { useState, useEffect } from "react";
import { appTasks } from "../services/api/apps";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { red } from "@mui/material/colors";
import {
  TableBody,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Paper,
  AppBar,
  Grid,
  Button,
  Typography,
  tableCellClasses,
  Container,
} from "@mui/material";

import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";
import DeleteIconButton from "../components/common/DeleteIconButton";
import ConfirmDialog from "../components/common/ConfirmDialog";
import { styled } from "@mui/material/styles";

export default function Relations() {
  const app = JSON.parse(localStorage.getItem("app"));
  const [domains, setAllDomains] = useState([]);
  const [relations, setAllRelations] = useState([]);
  const [linkType, setLinkType] = useState("");
  const [leftBO, setLefBO] = useState("");
  const [rightBO, setRightBO] = useState("");
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const relUrl = "application/" + app.id + "/businessObjectLink";

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .getDomains(app.id)
      .then((res) => {
        if (res) {
          console.log(res);
          setAllDomains(res);
          setState({
            ...state,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, []);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .getAll(relUrl)
      .then((res) => {
        if (res) {
          console.log(res);
          setAllRelations(res);
          setState({
            ...state,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, []);

  const inputTypes = [
    "One to One",
    "One to Many",
    "Many to One",
    "Many to Many",
  ];

  const deleteRelation = (id) => {
    console.log("Relation id ", id, " deleted!!");
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this relation?",
      onConfirm: () => {
        console.log("Yes");
        onDelete(id);
      },
    });
  };

  const onDelete = (id) => {
    setState({ ...state, loading: true });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    appTasks
      .remove(id, relUrl + "/" + id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newRelations = [...relations];
          const relationList = newRelations.filter(
            (relation) => relation.id != id
          );
          console.log(relationList);
          setAllRelations(relationList);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Attribute deleted successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      });
  };

  const handleSubmitForm = async (event) => {
    console.log(
      "Submitted Left BO",
      leftBO,
      " Relation ",
      linkType,
      " Right BO ",
      rightBO
    );
    if (leftBO === "" || linkType === "" || rightBO === "") {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "All fields are mandetory.",
          type: "error",
        },
        loading: false,
      });
      return;
    }
    if (leftBO === rightBO) {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "Both business object should not be same",
          type: "error",
        },
        loading: false,
      });
      return;
    }
    const payload = {
      appId: app.id,
      businessObjectLinkName: linkType,
      id: 0,
      leftBusinessObjectId: leftBO,
      rightBusinessObjectId: rightBO,
    };

    console.log(payload);
    setState({ ...state, loading: true });
    appTasks
      .post(payload, relUrl)
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New attribute created");
          let newRelations = [...relations];
          console.log(newRelations);
          newRelations.push(res);
          setAllRelations(newRelations);
          setLefBO("");
          setRightBO("");
          setLinkType("");
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "New attribute created successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: error.details,
            type: "error",
          },
        });
      });
    setState({ ...state, loading: false });
  };

  const setNotify = (notify) => {
    console.log("Set nogify", notify);
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    console.log("Set loading..", loading);
    setState({ ...state, loading: false });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const useGetAllRelations = relations.map((relation, i) => {
    return (
      <StyledTableRow
        key={i}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <StyledTableCell component="th" scope="row">
          {relation.leftBusinessObjectName}
        </StyledTableCell>
        <StyledTableCell>{relation.businessObjectLinkName}</StyledTableCell>
        <StyledTableCell>{relation.rightBusinessObjectName}</StyledTableCell>
        <StyledTableCell>
          <DeleteIconButton
            tooltip="Delete relation"
            deleteme={deleteRelation}
            id={relation.id}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  return (
    <React.Fragment>
      <Container component="main" sx={{ flex: 1, py: 5, px: 4 }}>
        <Loading loading={state.loading} setLoading={setLoading} />
        <Notification notify={state.notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Typography
              sx={{ my: 2, mx: 3 }}
              variant="h3"
              color="text.secondary"
              align="center"
            >
              Business Object Relations
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="l-type-select">
                    Business Object
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="l-type-select"
                    id="leftBusinessObjectId"
                    label="Input Type"
                    value={leftBO}
                    onChange={(event) => {
                      console.log(event.target.value);
                      setLefBO(event.target.value);
                    }}
                  >
                    {domains.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.businessObjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="type-select">
                    Relation Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="type-select"
                    id="demo-simple-select"
                    label="Input Type"
                    value={linkType}
                    onChange={(event) => {
                      setLinkType(event.target.value);
                    }}
                  >
                    {inputTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="r-type-select">
                    Business Object
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="r-type-select"
                    id="demo-simple-select"
                    label="Input Type"
                    value={rightBO}
                    onChange={(event) => {
                      setRightBO(event.target.value);
                    }}
                  >
                    {domains.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.businessObjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <></>
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                style={{ paddingTop: 25, paddingRight: 15 }}
              >
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  onClick={() => handleSubmitForm()}
                >
                  Add Business Object Relation
                </Button>
              </Grid>
            </Grid>
          </AppBar>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <TableContainer justify="center">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Business Object</StyledTableCell>
                    <StyledTableCell>Relation type</StyledTableCell>
                    <StyledTableCell>Business Object</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {!state.loading ? (
                    relations && relations.length > 0 ? (
                      <>{useGetAllRelations}</>
                    ) : (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell colSpan={4} align="center">
                          <Typography
                            color="text.secondary"
                            align="center"
                            variant="body1"
                          >
                            No Relations
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
