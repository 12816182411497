import React from "react";
import { Controller } from "react-hook-form";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export const FormRadio = ({
  name,
  control,
  rules,
  onChange,
  groupName,
  groupList = [{}],
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      onChange={onChange}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <RadioGroup
            row
            name={groupName}
            value={value}
            onChange={(event) => {
              onChange(event);
            }}
          >
            {groupList?.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText error={!!error}>
            {error ? error.message : null}
          </FormHelperText>
        </>
      )}
      rules={rules}
    />
  );
};
