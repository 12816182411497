import React, { useState, useEffect } from "react";
import { appTasks } from "../services/api/apps";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Paper,
  AppBar,
  Grid,
  Button,
  TextField,
  Typography,
  ListItemText,
  Card,
  List,
  ListItem,
  useTheme,
  ListItemSecondaryAction,
  Container,
  Box,
  Divider,
} from "@mui/material";

import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";
import { Link } from "react-router-dom";
import Text from "../components/common/Text";
import DeleteIconButton from "../components/common/DeleteIconButton";
import ConfirmDialog from "../components/common/ConfirmDialog";
import AddIcon from "@mui/icons-material/Add";
import { camelize, toCamelCaseFirstLower } from "../utils/stringUtils";

export default function Actions() {
  const app = JSON.parse(localStorage.getItem("app"));
  const [domains, setAllDomains] = useState([]);
  const [actions, setAllActions] = useState([]);
  const [actionType, setActionType] = useState("");
  const [businesObj, setBusinessObj] = useState("");
  const [actionName, setActionName] = useState("");
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
    custom: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const actionUrl = "application/" + app.id + "/actions";
  const theme = useTheme();
  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .getDomains(app.id)
      .then((res) => {
        if (res) {
          console.log(res);
          setAllDomains(res);
          setState({
            ...state,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, []);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    appTasks
      .getAll(actionUrl)
      .then((res) => {
        if (res) {
          console.log(res);
          setAllActions(res);
          setState({
            ...state,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, []);

  const actionTypes = ["Create", "Read", "Update", "Delete", "List", "Custom"];

  const deleteAction = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this action?",
      onConfirm: () => {
        console.log("Yes");
        onDelete(id);
      },
    });
  };

  const onDelete = (id) => {
    setState({ ...state, loading: true });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    appTasks
      .remove(id, actionUrl + "/" + id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newActions = [...actions];
          const actionList = newActions.filter((action) => action.id != id);
          setAllActions(actionList);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Action deleted successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      });
  };

  const handleSubmitForm = async (event) => {
    console.log("Type", actionType, " BO ", businesObj, " Name ", actionName);
    if (actionType === "" || businesObj === "") {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "All fields are mandetory.",
          type: "error",
        },
        loading: false,
      });
      return;
    }

    if (actionType === "Custom" && actionName === "") {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "Please enter custom action name.",
          type: "error",
        },
        loading: false,
      });
      return;
    }

    let payload = {
      action: {
        actionType: actionType,
      },
      appId: app.id,
      businessObjectId: businesObj,
    };

    if (actionType === "Custom") {
      payload = {
        action: {
          actionName: actionName,
          actionType: "Custom",
        },
        appId: app.id,
        businessObjectId: businesObj,
      };
    }

    console.log(payload);
    setState({ ...state, loading: true });
    appTasks
      .post(payload, actionUrl)
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New attribute created");
          let newActions = [...actions];
          newActions.push(res);
          setAllActions(newActions);
          setBusinessObj("");
          setActionName("");
          setActionType("");
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "New attribute created successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });
    setState({ ...state, loading: false });
  };

  const setNotify = (notify) => {
    console.log("Set nogify", notify);
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    console.log("Set loading..", loading);
    setState({ ...state, loading: false });
  };

  const useGetAllActionList = actions.map((action, index) => {
    return (
      <ListItem
        key={index}
        sx={{
          py: 2,
          borderBottom: "1px solid #E8EAEE",
          color: `${theme.colors.primary.main}`,
          "&:hover": { color: `${theme.colors.primary.dark}` },
        }}
        button
        component={Link}
        state={{
          ...action,
        }}
        to="/dashboard/actionsteps"
      >
        <ListItemText
          primary={<Text color="black">{action.businessObjectName}</Text>}
          primaryTypographyProps={{
            variant: "h2",
            fontWeight: 500,
            color: "textPrimary",
            gutterBottom: true,
            noWrap: true,
          }}
          secondary={
            <Text>
              {" Action : " + action.action.actionType}{" "}
              {action.action.actionName != null
                ? "[" + action.action.actionName + "]"
                : ""}
            </Text>
          }
          secondaryTypographyProps={{
            variant: "h4",
            noWrap: true,
            color: "textSecondary",
          }}
        />

        <ListItemSecondaryAction
          style={{ right: "5%", left: "auto" }}
          edge="end"
        >
          <DeleteIconButton
            tooltip={"Delete action"}
            edge="end"
            color="primary"
            deleteme={deleteAction}
            id={action.id}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  });

  return (
    <React.Fragment>
      <Container component="main" sx={{ flex: 1, py: 5, px: 4 }}>
        <Loading loading={state.loading} setLoading={setLoading} />
        <Notification notify={state.notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Paper sx={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
          <Typography
            sx={{ my: 2, mx: 3 }}
            variant="h3"
            color="text.secondary"
            align="center"
          >
            Actions
          </Typography>
          <Divider />
          <Box
            sx={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 5,
            }}
          >
            <Grid container spacing={2} sx={{ paddingTop: 2 }}>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="l-type-select" size="small">
                    Business Object
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="l-type-select"
                    id="leftBusinessObjectId"
                    label="Input Type"
                    value={businesObj}
                    onChange={(event) => {
                      console.log(event.target.value);
                      setBusinessObj(event.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Domain</em>
                    </MenuItem>
                    {domains.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.businessObjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="type-select" size="small">
                    Action Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="type-select"
                    id="demo-simple-select"
                    label="Input Type"
                    value={actionType}
                    onChange={(event) => {
                      setActionType(event.target.value);
                      if (event.target.value === "Custom") {
                        console.log("Custom");
                      }
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {actionTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                {actionType === "Custom" ? (
                  <FormControl fullWidth>
                    <TextField
                      // placeholder="Enter Label"
                      onChange={(event) => {
                        let name = camelize(
                          toCamelCaseFirstLower(event.target.value)
                        );
                        setActionName(name);
                      }}
                      id="Action Name"
                      label="Custom Name"
                      variant="outlined"
                      value={actionName}
                      size="small"
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={6} md={4}></Grid>
              <Grid item xs={6} md={4} style={{ paddingTop: 25 }}>
                <Button
                  size="small"
                  variant="contained"
                  fullWidth
                  onClick={() => handleSubmitForm()}
                >
                  <AddIcon />
                  Add New Action
                </Button>
              </Grid>
              <Grid item xs={6} md={4}></Grid>
            </Grid>
          </Box>
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item lg={12} xs={12}>
              <Card sx={{ height: "100%" }}>
                <List disablePadding>{actions && useGetAllActionList}</List>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
