import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const DialogBox = ({
  maxWidth = "md",
  isOpen,
  onClose,
  title,
  subtitle,
  childern,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={isOpen}
        sx={{ m: 0, p: 2 }}
      >
        <DialogTitle sx={{textAlign:"center", fontSize:20}}>
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>{childern}</DialogContent>
      </Dialog>
    </>
  );
};
