import React, { useState, useEffect } from "react";
import { appTasks } from "../services/api/apps";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Paper,
  Grid,
  Button,
  Typography,
  TextField,
  AppBar,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableBody,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  tableCellClasses,
  Container,
  Box,
} from "@mui/material";

import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";
import ConfirmDialog from "../components/common/ConfirmDialog";
import Label from "../components/common/Label";

import { styled } from "@mui/material/styles";
import DeleteIconButton from "../components/common/DeleteIconButton";
import { toCamelCaseFirstLower, camelize } from "../utils/stringUtils";


export default function BODetails(props) {
  const { id, boname } = props;
  const location = useLocation();
  //const businessObject = location.state;
  const businessObject = { id: id, businessObjectName: boname };
  const app = JSON.parse(localStorage.getItem("app"));

  const [attributes, setAtteributes] = useState([]);
  const [validations, setValidations] = useState([]);
  const [dispValidation, setDispValidation] = useState(false);
  const [validationType, setValidationType] = useState("");
  const [label1, setLabel1] = useState({ isOpen: false, label: "", val: "" });
  const [label2, setLabel2] = useState({ isOpen: false, label: "", val: "" });

  const [type, setType] = useState("");
  const [label, setLabel] = useState("");
  const [name, setName] = useState("");
  const [required, setRequired] = useState(true);
  const [state, setState] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const apiUrl =
    "application/" +
    app.id +
    "/businessObject/" +
    businessObject.id +
    "/attributes";
  const attribValidationUrl = "metadata/validations";
  const inputTypes = [
    "Text",
    "Date",
    "Number",
    "Boolean",
    "Decimal",
    "Time",
    "List of Value",
    "File",
  ];
  const setNotify = (notify) => {
    setState({ ...state, notify: notify });
  };

  const setLoading = (loading) => {
    setState({ ...state, loading: false });
  };

  useEffect(() => {
    setState({ ...state, loading: true });
    appTasks
      .getAll(apiUrl)
      .then((res) => {
        if (res) {
          setAtteributes(res);
          setState({ ...state, loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false });
      });
  }, [businessObject.id]);

  useEffect(() => {
    setState({ ...state, loading: true });
    appTasks
      .getAll(attribValidationUrl)
      .then((res) => {
        if (res) {
          console.log(res);
          setValidations(res);
          setState({ ...state, loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false });
      });
  }, []);
  const handleSubmitForm = async (evecamelizent) => {
    let attribvalidationObject = {};
    console.log(
      "Label:",
      label,
      " Name:",
      name,
      " type:",
      type,
      " Required:",
      required
    );
    if (name === "" || label === "" || type === "") {
      setState({
        ...state,
        notify: {
          isOpen: true,
          message: "All fields are mandetory!",
          type: "error",
        },
      });
      return;
    }
    if (dispValidation) {
      if (validationType === "") {
        setState({
          ...state,
          notify: {
            isOpen: true,
            message: "Please select validation type",
            type: "error",
          },
        });
        return;
      }
      const item = validations.find(
        (item) => item.validationType === validationType
      );
      attribvalidationObject = { validationType: validationType };
      if (item.numOfInputs === 1) {
        if (label1.val === "") {
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Please enter " + label1.label,
              type: "error",
            },
          });
          return;
        }
        attribvalidationObject = {
          validationType: validationType,
          validationValue1: label1.val,
        };
      }
      if (item.numOfInputs === 2) {
        if (label1.val === "" || label2.val === "") {
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Please enter " + validationType + " values",
              type: "error",
            },
          });
          return;
        }
        attribvalidationObject = {
          validationType: validationType,
          validationValue1: label1.val,
          validationValue2: label2.val,
        };
      }
    }
    const payload = {
      appId: app.id,
      attributeDetails: {
        attribute: [
          {
            attributeLabel: label,
            attributeName: name,
            attributeType: type,
          },
        ],
      },
      businessObjectId: businessObject.id,
    };

    if (required && !dispValidation) {
      payload.attributeDetails.attribute[0].validations = {
        validation: [
          {
            validationType: "Required",
          },
        ],
      };
    }

    if (!required && dispValidation) {
      payload.attributeDetails.attribute[0].validations = {
        validation: [attribvalidationObject],
      };
    }

    if (required && dispValidation) {
      payload.attributeDetails.attribute[0].validations = {
        validation: [
          {
            validationType: "Required",
          },
          attribvalidationObject,
        ],
      };
    }

    console.log(payload);
    setState({ ...state, loading: true });

    appTasks
      .post(payload, apiUrl)
      .then((res) => {
        console.log(res);
        if (res) {
          console.log("New attribute created");
          let newAttributes = [...attributes];
          newAttributes.push(res);
          console.log(newAttributes);
          setAtteributes(newAttributes);
          setLabel("");
          setType("");
          setName("");
          setDispValidation(false);
          setValidationType("");
          setState({
            loading: false,
            notify: {
              isOpen: true,
              message: "New attribute created successfully",
              type: "success",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
      });

    setState({ ...state, loading: false });
  };

  const deleteAttribute = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this Attribute?",
      onConfirm: () => {
        console.log("Yes");
        onDelete(id);
      },
    });
    console.log("Delete Attribute ", id);
  };

  const onDelete = (id) => {
    setState({ ...state, loading: true });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    appTasks
      .remove(id, apiUrl + "/" + id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let newattributes = [...attributes];
          const attributeList = newattributes.filter(
            (attribute) => attribute.id != id
          );
          console.log(attributeList);
          setAtteributes(attributeList);
          setState({
            ...state,
            notify: {
              isOpen: true,
              message: "Attribute deleted successfully",
              type: "success",
            },
          });
        }
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.green,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 10,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const addValidation = (event) => {
    const name = event.target.value;
    const item = validations.find((item) => item.validationType === name);
    setLabel1({ isOpen: false, label: "", val: "" });
    setLabel2({ isOpen: false, label: "", val: "" });
    if (item.numOfInputs == 1) {
      setLabel1({ isOpen: true, label: item.labels[0], val: "" });
    }
    if (item.numOfInputs == 2) {
      setLabel1({ isOpen: true, label: item.labels[0], val: "" });
      setLabel2({ isOpen: true, label: item.labels[0], val: "" });
    }
    setValidationType(item.validationType);
  };
  const useGetAllAttributes = attributes.map((attributes, i) => {
    const attList = attributes.attributeDetails.attribute;
    return attList.map((attribute, i) => (
      <StyledTableRow
        key={i}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <StyledTableCell scope="row">
          {attribute.attributeLabel}
        </StyledTableCell>
        {/* <StyledTableCell>{attribute.attributeName}</StyledTableCell> */}
        <StyledTableCell>{attribute.attributeType}</StyledTableCell>
        <StyledTableCell>
          {attribute.validations.validation?.map((validation, j) => {
            return (
              <Typography variant="caption" key={j}>
                {validation.validationType + ", "}
              </Typography>
            );
          })}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "center" }}>
          <DeleteIconButton
            size="small"
            tooltip={"Delete " + attribute.attributeLabel}
            deleteme={deleteAttribute}
            id={attributes.id}
          />
        </StyledTableCell>
      </StyledTableRow>
    ));
  });

  return (
    <Box component="main" sx={{ flex: 0, py: 0, padding: 0, margin: 0 }}>
      <Loading loading={state.loading} setLoading={setLoading} />
      <Notification notify={state.notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Paper
        sx={{
          margin: "auto",
          overflow: "auto",
          height: "90vh",
          backgroundColor: "#fff",
          borderRadius: 0,
        }}
        elevation={0}
      >
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 5,
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{ my: 2, mx: 3 }}
            variant="h4"
            color="text.secondary"
            align="center"
          >
            {"Atrributes of " + businessObject?.businessObjectName}
          </Typography>
          <Divider />
          <Grid container spacing={2} sx={{ my: 0 }}>
            <Grid item xs={6} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder="Enter Label"
                onChange={(event) => {
                  let nameText = event.target.value
                    .toString()
                    .toLocaleLowerCase();
                  setLabel(event.target.value);
                  let name = camelize(event.target.value);
                  setName(name);
                }}
                id="label"
                label="Label"
                variant="outlined"
                value={label}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder="Enter name"
                onChange={(event) => {
                  let name = camelize(
                    toCamelCaseFirstLower(event.target.value)
                  );
                  setName(name);
                }}
                id="name"
                label="Name"
                variant="outlined"
                value={name}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Input Type
                </InputLabel>
                <Select
                  size="small"
                  id="demo-simple-select"
                  value={type}
                  label="Input Type"
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                >
                  <MenuItem value="" disabled selected>
                    Select Input Type
                  </MenuItem>
                  {inputTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={dispValidation}
                      onChange={(event) => {
                        setDispValidation(event.target.checked);
                      }}
                    />
                  }
                  label="Validation"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      defaultChecked
                      onChange={(event) => {
                        const req = !required;
                        setRequired(req);
                      }}
                    />
                  }
                  label="Required"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} md={4}></Grid>
            <Grid
              item
              xs={6}
              md={4}
              display={dispValidation ? "visible" : "none"}
            >
              <FormControl fullWidth>
                <InputLabel id="validation-select-label" size="small">
                  Validation Type
                </InputLabel>
                <Select
                  size="small"
                  id="validation-select-label"
                  defaultValue={validationType}
                  value={validationType}
                  label="Validation Type"
                  onChange={(event) => {
                    addValidation(event);
                  }}
                >
                  <MenuItem value="">Select Validation</MenuItem>
                  {validations.map((item, index) => (
                    <MenuItem key={index} value={item.validationType}>
                      {item.validationType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              display={label1.isOpen && dispValidation ? "visible" : "none"}
            >
              <TextField
                size="small"
                fullWidth
                placeholder=""
                onChange={(event) => {
                  setLabel1({ ...label1, val: event.target.value });
                }}
                id="name"
                variant="outlined"
                label={label1.label}
                value={label1.val}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              display={label2.isOpen && dispValidation ? "visible" : "none"}
            >
              <TextField
                size="small"
                fullWidth
                placeholder=""
                onChange={(event) => {
                  setLabel2({ ...label2, val: event.target.value });
                }}
                id="name"
                variant="outlined"
                label={label2.label}
                value={label2.val}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6} md={3}>
              <></>
            </Grid>
            <Grid
              item
              xs={8}
              md={6}
              style={{ paddingTop: 25, paddingRight: 15 }}
            >
              <FormGroup>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  onClick={() => handleSubmitForm()}
                >
                  Create New Attribute
                </Button>
              </FormGroup>
            </Grid>
            <Grid item xs={6} md={3}>
              <></>
            </Grid>
          </Grid>
        </AppBar>
        <Divider />
        <Grid container spacing={0} alignItems="center" justify="center">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Label</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Validation</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    Delete
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!state.loading ? (
                  <>{attributes && useGetAllAttributes}</>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Box>
  );
}
