import React, { useState, useEffect } from "react";
import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  Button,
  Container,
  List,
  Paper,
  Typography,
  ListItem,
  Grid,
  IconButton,
  ListItemSecondaryAction,
} from "@mui/material";
import api from "../services/api/api";
import { useForm } from "react-hook-form";
import DownloadIcon from "@mui/icons-material/Download";

export default function Deployment() {
  const app = JSON.parse(localStorage.getItem("app"));
  const [deployments, setDeployments] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiURL = "application/" + app.id + "/deployment";
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const queryClient = useQueryClient();
  const getDeployments = async () => {
    queryClient.invalidateQueries("deployments");
    const response = await api.get(apiURL + "?appId=" + app.id);
    console.log(response.data);
    setDeployments(response.data);
  };
  useEffect(async () => {
    await getDeployments();
    console.log(deployments);
  }, []);
  const { handleSubmit } = useForm();

  const submitDeployment = async (data) => {
    console.log(data);
    return await api.post(apiURL, data);
  };

  const { mutate, isLoading } = useMutation(submitDeployment, {
    onSuccess: async (data) => {
      console.log(data.response);
      setNotify({
        isOpen: true,
        message: "Application download successfully!",
        type: "success",
      });
      getDeployments();
    },
    onError: async (error) => {
      console.log(
        error.response.status,
        "there was an error",
        error.response.data
      );
      setNotify({
        isOpen: true,
        message:
          "There is error while generating application. Please try again.",
        type: "error",
      });
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries("settings");
    // },
  });

  const onSubmit = (data) => {
    console.log(data);
    mutate({ appId: app.id });
  };
  const download = async (id) => {
    console.log(id);
    setLoading(true);
    const response = await api.download(apiURL + "/download/" + id);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", id + ".zip"); //or any other extension
    document.body.appendChild(link);
    link.click();
    setLoading(false);
    console.log("Download completed");
  };
  return (
    <React.Fragment>
      <Notification notify={notify} setNotify={setNotify} />
      <Loading loading={isLoading} setLoading={() => {}} />
      <Container component="main" sx={{ flex: 1, py: 5, px: 4 }}>
        <Paper
          sx={{
            maxWidth: 936,
            margin: "auto",
            overflow: "hidden",
            padding: 5,
          }}
        >
          <Typography
            sx={{ my: 1 }}
            color="text.secondary"
            variant="h3"
            align="center"
          >
            Application Deployment
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ paddingTop: 5 }}>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Generate Code
                </Button>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <List >
                  {deployments &&
                    deployments?.map((deploy, index) => {
                      return (
                        <ListItem key={index}>
                          {deploy.createdDate}

                          <ListItemSecondaryAction
                            style={{ right: "5%", left: "auto" }}
                            edge="end"
                          >
                            <IconButton
                              onClick={() => download(`${deploy.id}`)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
