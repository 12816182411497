import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { styled } from "@mui/material/styles";

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
       background: ${theme.colors.error.lighter};
       color: ${theme.colors.error.main};
       padding: ${theme.spacing(0.5)};
  
       &:hover {
        background: ${(theme.colors.error.lighter, 0.4)};
       }
  `
);

export default function DeleteIconButton(props) {
  const { tooltip, deleteme, id } = props;
  return (
    <Tooltip arrow title={tooltip}>
      <IconButtonError
        onClick={() => {
          deleteme(`${id}`);
        }}
      >
        <DeleteTwoToneIcon fontSize="small" />
      </IconButtonError>
    </Tooltip>
  );
}
