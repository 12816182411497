import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import Navigator from "./Navigator";
import Header from "./Header";
import theme from "../components/common/Theme";
import Copyright from "../components/common/Copyright";
import { appTasks } from "../services/api/apps";
import { useLocation } from "react-router-dom";

import Notification from "../components/common/Notification";
import Loading from "../components/common/Loading";
import { PureLightTheme } from "../components/common/PureLightTheme";

const drawerWidth = 250;

export default function Dashboard() {
  const location = useLocation();
  const appID = location.state?.appId;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [values, setValues] = useState({
    notify: {
      isOpen: false,
      message: "",
      type: "success",
    },
    loading: false,
    name: "",
  });

  const [appDetail, setAppDetails] = useState({});

  const setNotify = (notify) => {
    setValues({ ...values, notify: notify });
  };

  const setLoading = (loading) => {
    setValues({ ...values, loading: false });
  };

  /**
   * Get appdetails by id
   */
  useEffect(() => {
    console.log("useEffect : ", appID);
    if (appID === undefined || appID === null) {
      const app = JSON.parse(localStorage.getItem("app"));
      setAppDetails(app);
      return;
    }
    setValues({
      ...values,
      loading: true,
    });
    appTasks
      .getSingle(appID)
      .then((res) => {
        if (res) {
          console.log(res);
          setAppDetails({ id: res.id, appName: res.appName });
          localStorage.setItem("app", JSON.stringify(res));
          setValues({
            ...values,
            loading: false,
          });
        }
        console.log("apps loading done");
      })
      .catch((error) => {
        console.log("apps loading error");
        console.log(error);
        setValues({
          ...values,
          notify: {
            isOpen: true,
            message: "Something went wrong! Please try again.",
            type: "error",
          },
          loading: false,
        });
      });
  }, [appID]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={PureLightTheme}>
      <Loading loading={values.loading} setLoading={setLoading} />
      <Notification notify={values.notify} setNotify={setNotify} />
      <CssBaseline />

      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          />
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            heading={appDetail.appName}
          />
          {/* <Box
            
            sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
          > 
            <Container component="main" sx={{ flex: 1, py: 5, px: 4,  }}>*/}
          <Box component="main">
            <Outlet />
          </Box>
          {/* </Container>
           </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
